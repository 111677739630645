import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Row, Col, Button } from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import { InputGroup } from '@paljs/ui/Input';
import styled from 'styled-components';
import Alert from '@paljs/ui/Alert';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const DELETE_IMAGE_MUTATION = gql`
  mutation onDeleteImage($_key: ID!) {
    onDeleteImage(_key: $_key)
  }
`;

const IMAGE_UPLOAD_MUTATION = gql`
  mutation onFileUpload($file: String!) {
    onFileUpload(file: $file)
  }
`;

const SAVE_IMAGE_MUTATION = gql`
  mutation onNewImage($imageInput: ImageInput!) {
    onNewImage(image: $imageInput)
  }
`;

export default function Tag() {
  const [images, setImages] = useState([]);
  const [tagSlug, setTagSlug] = useState('');
  const [deleteImageMutation] = useMutation(DELETE_IMAGE_MUTATION);
  const [addImage] = useMutation(SAVE_IMAGE_MUTATION);
  const [imagePreview, setImagePreview] = useState('');
  const [uploadImage] = useMutation(IMAGE_UPLOAD_MUTATION);
  const [imageDescription, setImageDescription] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);

  if (typeof window !== 'undefined') {
    let params = new URLSearchParams(document.location.search.substring(1));

    params.forEach((k) => {
      console.log(k);
    });
    console.log(params.get('t'));

    if (tagSlug === '') {
      setTagSlug(params.get('t') || '');
    }
  }

  const q = gql`
    query imagesByTag($tag: String!) {
      imagesByTag(tag: $tag) {
        _key
        description
        file
      }
    }
  `;

  const { data, loading, error } = useQuery(q, {
    variables: {
      tag: tagSlug,
    },
  });

  if (!data) {
    return <div>loading...</div>;
  }

  if (images.length < 1) {
    setImages(
      data.imagesByTag.map((t) => {
        return {
          _key: t._key,
          description: t.description,
          file: t.file,
        };
      }),
    );
  }

  const deleteImage = (_key: string, ind: number) => {
    const { data, error } = deleteImageMutation({
      variables: { _key },
    });

    images.splice(ind, 1);
    const list = [];
    images.forEach((i) => {
      list.push(i);
    });
    setImages(list);
  };

  const onSingleImageDrop = async (evt) => {
    const upload = new Promise<string>((resolve) => {
      var reader = new FileReader();

      reader.onload = async (e) => {
        const { data, errors } = await uploadImage({
          variables: {
            file: e.target.result,
          },
        });

        resolve(data.onFileUpload);
      };

      reader.onerror = function (e) {
        // error occurred
        console.log('Error : ' + e.type);
      };

      reader.readAsDataURL(evt[0]);
    });

    const data = await upload;
    setImagePreview(data);
  };

  const saveImage = async () => {
    setIsUploading(true);

    const { data, errors } = await addImage({
      variables: {
        imageInput: {
          file: imagePreview,
          description: imageDescription,
          tags: [tagSlug],
        },
      },
    });

    if (data) {
      setImagePreview('');
      setImageDescription('');
    }

    setIsUploading(false);
    setOnSuccess(true);

    setTimeout(() => {
      setOnSuccess(false);
    }, 3500);
  };

  return (
    <>
      {onSuccess && (
        <Alert
          style={{ boxShadow: '1px 1px 3px #ccc', position: 'fixed', top: '5.5rem', zIndex: 500 }}
          status="Success"
        >
          Successfully added new image.
        </Alert>
      )}
      <Row>
        <h2 style={{ textTransform: 'uppercase', textAlign: 'center', padding: '1.5em', marginBottom: '1em' }}>
          Images in {tagSlug}
        </h2>
      </Row>
      <Row style={{ marginBottom: '2rem' }}>
        <Col xs={12}>
          {imagePreview !== '' && <img src={imagePreview} style={{ maxWidth: '200px', maxHeight: '100px' }} />}
          <ImageUploader
            withIcon={true}
            buttonText="Choose images"
            singleImage={true}
            onChange={(evt) => {
              onSingleImageDrop(evt);
            }}
            imgExtension={['.jpg', '.png', '.jpeg']}
            maxFileSize={5242880}
          />
          {imagePreview && (
            <>
              <p>
                <strong>Image Description</strong>
              </p>
              <Input fullWidth size="Medium">
                <input
                  maxLength="150"
                  placeholder="Description:"
                  type="text"
                  onChange={(evt) => {
                    setImageDescription(evt.target.value);
                  }}
                />
              </Input>
              <Button disabled={isUploading || imageDescription === ''} onClick={saveImage}>
                save image
              </Button>
              {isUploading && <p>one moment. Image is being saved.</p>}
            </>
          )}
        </Col>
      </Row>
      <Row>
        {images &&
          images.map((i, k) => (
            <Col key={k} xs={12} sm={6} md={4} lg={3} style={{ height: '240px' }}>
              <img src={i.file} alt={i.description} style={{ maxWidth: '100%', height: '100px' }} />
              <p>{i.description}</p>
              <p>
                <a
                  style={{ color: '#555', cursor: 'pointer' }}
                  onClick={() => {
                    deleteImage(i._key, k);
                  }}
                  className="secondary"
                >
                  delete
                </a>
              </p>
            </Col>
          ))}
      </Row>
    </>
  );
}
